// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-templates-product-js": () => import("./../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-product-sub-types-js": () => import("./../src/templates/ProductSubTypes.js" /* webpackChunkName: "component---src-templates-product-sub-types-js" */)
}

